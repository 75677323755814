import React from 'react'
import SEO from '../components/seo'
import BlogGrid from '../components/BlogGrid'

const BlogPage = () => (
  <>
    <SEO title="Blog" />
    <h1>Blog</h1>
    <BlogGrid />
  </>
)

export default BlogPage
