import React from 'react'
import { StaticQuery, Link } from 'gatsby'
import _ from 'lodash'
import Img from 'gatsby-image'
import styled from 'styled-components'

const BlogContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;

  a {
    font-size:1.3em;
    font-weight:bold;
  }

  @media screen and (max-width: 800px) {
    display: block;
  }
`

const BlogGrid = () => (
  <StaticQuery
    query={graphql`
      query BlogIndexQuery {
        allShopifyArticle {
          edges {
            node {
              title
              id
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <BlogContent>
        {data.allShopifyArticle.edges.map(({ node }) => (
          <div>
            <Img
              fluid={node.image.localFile.childImageSharp.fluid}
              key={node.id}
              alt={node.title}
            />
            <Link to={`/blog/${_.kebabCase(node.title)}`}>{node.title}</Link>
          </div>
        ))}
      </BlogContent>
    )}
  />
)

export default BlogGrid
